import { INDIVIDUAL_DISTANCE_GOAL } from '../../../constants/goals';
import DistanceIndicator from '../../distanceIndicator/DistanceIndicator';
import metro from '../assets/metro.png';
import passengers from '../assets/passengers.png';
import week from '../assets/week.png';
import MediaCategory from '../MediaCategory';
import MediaContainer from '../MediaContainer';

interface Content {
  label: React.ReactNode;
  media: React.ReactNode;
  text: string[];
  title: string;
}

const content: Content[] = [
  {
    label: null,
    title: 'Doel van de game',
    text: [
      'Hey Social Worker! Wat fijn dat je er bent. Vele reizigers staan te wachten op stations en we hebben jouw hulp hard nodig om hen op tijd bij hun bestemming te krijgen. Wil jij ons helpen?',
    ],
    media: (
      <MediaContainer>
        <img src={metro} alt="Metro Game" draggable={false} />
      </MediaContainer>
    ),
  },
  {
    label: <DistanceIndicator />,
    title: 'Kilometers',
    text: [
      'Je brengt passagiers naar hun bestemming door opdrachten te voltooien. Wanneer je een opdracht voltooid ga je naar het volgende station en leg je kilometers af.',
      `Als je ${INDIVIDUAL_DISTANCE_GOAL} kilometer hebt afgelegd verdien je een vraag van de aankomende toets. De groep die de meeste kilometers verdiend wordt nog extra beloond met een mooie prijs! Let op, grotere opdrachten zijn méér kilometers waard.`,
    ],
    media: null,
  },
  {
    label: null,
    title: 'Hoofd-hart-handen lijn',
    text: [
      'Er zijn drie metrolijnen met elk een eigen thema. Kies het thema dat jou het meeste ligt en voltooi de opdrachten binnen deze lijn. Toch niet helemaal het juiste thema gekozen of ben je benieuwd naar een ander thema? Je kan op verschillende punten wisselen van lijn.',
    ],
    media: <MediaCategory />,
  },
  {
    label: null,
    title: 'Actieve week',
    text: [
      'Sommige stations zijn nog gesloten en openen pas over een paar weken. Elke week openen nieuwe stations met opdrachten die horen bij die lesweek. Stations uit eerdere weken zijn later nog steeds beschikbaar.',
    ],
    media: (
      <MediaContainer>
        <img src={week} alt="Actieve Week" draggable={false} />
      </MediaContainer>
    ),
  },
  {
    label: null,
    title: 'Aantal passagiers',
    text: [
      'Passagiers staan te wachten op de stations. Aan jou de taak om hen op tijd bij hun bestemming te krijgen. Door opdrachten te voltooien breng je passagiers naar hun bestemming.',
      'Passagiers zijn echter wat ongeduldig. Per dag van een onvoltooide opdracht vertrekken 5 mensen. Hoe eerder je de opdracht voltooid, hoe meer passagiers je meeneemt en hoe hoger je score wordt! Help jij ons de passagiersscore zo hoog mogelijk te krijgen? Succes en zet hem op!',
    ],
    media: (
      <MediaContainer>
        <img src={passengers} alt="Aantal Passagiers" draggable={false} />
      </MediaContainer>
    ),
  },
];

export default content;
