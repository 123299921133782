import { START_STATION } from '../../constants/stations';
import {
  COMPLETE_ASSIGNMENT,
  SET_ACTIVE_ASSIGNMENT,
  SET_INITIAL_STATE,
  SET_PLAYER_POSITION,
  SET_PLAYER_STATE,
  SET_TUTORIAL_COMPLETED_AT,
} from '../constants/actionTypes';

export const completeAssignment = (
  assignmentId,
  distance = 0,
  passengerScore = 0
) => ({
  type: COMPLETE_ASSIGNMENT,
  assignmentId,
  distanceToAdd: distance,
  passengerScore,
});

export const setPlayerPosition = (position) => ({
  type: SET_PLAYER_POSITION,
  position,
});

export const setActiveAssignment = (assignmentId) => ({
  type: SET_ACTIVE_ASSIGNMENT,
  assignmentId,
});

export const setTutorialCompletedAt = (tutorialCompletedAt) => ({
  type: SET_TUTORIAL_COMPLETED_AT,
  tutorialCompletedAt,
});

export const setPlayerState = (
  id,
  name,
  completedAssignments,
  position,
  distance,
  passengerScore,
  tutorialCompletedAt
) => ({
  type: SET_PLAYER_STATE,
  id,
  completedAssignments,
  distance: distance || 0,
  passengerScore,
  name: name || '',
  position: position || START_STATION,
  tutorialCompletedAt,
});

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});
