import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Classmate } from '../../../services/ClassmatesService';
import type { RootState } from '../..';

const initialState: Classmate[] = [];

const classmatesSlice = createSlice({
  name: 'classmates',
  initialState,
  reducers: {
    setClassmates: (_state, action: PayloadAction<Classmate[]>) =>
      action.payload,
  },
});

export const { setClassmates } = classmatesSlice.actions;

export const selectClassmates = (state: RootState) => state.classmates;

export const selectClassmatesTotalDistance = createSelector(
  selectClassmates,
  (classmates) =>
    classmates.reduce(
      (previousValue, currentValue) => previousValue + currentValue.distance,
      0
    )
);

export default classmatesSlice.reducer;
