// TODO dry
import { ReactComponent as HandIcon } from '../../assets/hand_icon.svg';
import { ReactComponent as HeadIcon } from '../../assets/head_icon.svg';
import { ReactComponent as HeartIcon } from '../../assets/heart_icon.svg';
import styles from './MediaCategory.module.scss';

const categories = [
  {
    title: 'Leerlijn Hoofd',
    text: 'Onderzoeken',
    icon: <HeadIcon />,
    color: 'purple',
  },
  {
    title: 'Leerlijn Hart',
    text: 'Jouw omgeving',
    icon: <HeartIcon />,
    color: 'yellow',
  },
  {
    title: 'Leerlijn Hand',
    text: 'Doen',
    icon: <HandIcon />,
    color: 'pink',
  },
];

function MediaCategory() {
  return (
    <div>
      {categories.map(({ title, text, icon, color }) => (
        <div key={color} className={styles.category}>
          <div className={styles.icon}>{icon}</div>
          <div>
            <h3 className={styles[color]}>{title}</h3>
            <p>{text}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MediaCategory;
