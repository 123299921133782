import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Content, PlayerPosition } from '../../../services/ContentService';
import assignmentsWithForeignKeys from '../../../utilities/assignmentsWithForeignKeys';
import dayjsTz from '../../../utilities/dayjsTz';
import getActiveWeek from '../../../utilities/getActiveWeek';
import type { RootState } from '../..';

const initialState: Content & { fetchedAt?: string } = {
  assignment_types: [],
  assignments: [],
  stations: [],
  weeks: [],
  fetchedAt: undefined,
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setContent: (_state, { payload }: PayloadAction<Content>) => ({
      ...payload,
      fetchedAt: dayjsTz().format(),
    }),
  },
});

export const { setContent } = contentSlice.actions;

export const selectContent = (state: RootState) => state.content;

/** Content */
export const selectWeeks = createSelector(
  selectContent,
  (content) => content.weeks
);
export const selectAssignments = createSelector(
  selectContent,
  (content) => content.assignments
);
export const selectAssignmentsWithForeignKeys = createSelector(
  selectContent,
  (content) => assignmentsWithForeignKeys(content)
);
export const selectFetchedAt = createSelector(
  selectContent,
  (content) => content.fetchedAt
);

/** Assignment */
export const selectAssignmentByPosition = (stationRef?: PlayerPosition) =>
  createSelector(selectAssignmentsWithForeignKeys, (assignments) =>
    assignments.find(({ station }) => station?.reference === stationRef)
  );

/** Week */
export const selectActiveWeek = createSelector(selectWeeks, (weeks) =>
  getActiveWeek(weeks)
);

export default contentSlice.reducer;
