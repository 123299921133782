import { ROUTE_LOGIN } from '../../constants/routes';
import { SET_ROUTE } from '../constants/actionTypes';

const initialState = {
  route: ROUTE_LOGIN,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTE:
      return { ...state, route: action.route };
    default:
      return state;
  }
};

export const getRoute = (state) => state.route;

export default app;
