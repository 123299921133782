export const SET_ROUTE = 'SET_ROUTE';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

export const SET_PLAYER_POSITION = 'SET_PLAYER_POSITION';
export const COMPLETE_ASSIGNMENT = 'COMPLETE_ASSIGNMENT';
export const SET_ACTIVE_ASSIGNMENT = 'SET_ACTIVE_ASSIGNMENT';
export const SET_TUTORIAL_COMPLETED_AT = 'SET_TUTORIAL_COMPLETED_AT';
export const SET_PLAYER_STATE = 'SET_PLAYER_STATE';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
