import { API_URL } from '../constants/api';
import HttpService from './HttpService';

interface Login {
  token: string;
}

interface LoginPayload {
  code: string;
}

class AuthService extends HttpService {
  static login(code: LoginPayload['code']) {
    return super.post<Login, LoginPayload>(`${API_URL}/login`, {
      code,
    });
  }

  static logout(accessToken: string) {
    return super.post(`${API_URL}/logout`, undefined, accessToken);
  }
}

export default AuthService;
