import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Answer } from '../../../services/AnswersService';
import type { RootState } from '../..';

const initialState: Answer[] = [];

const answersSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    setAnswers: (_state, { payload }: PayloadAction<Answer[]>) => payload,
    addAnswer: (state, { payload }: PayloadAction<Answer>) => [
      ...state,
      payload,
    ],
  },
});

export const { addAnswer, setAnswers } = answersSlice.actions;

export const selectAnswers = (state: RootState) => state.answers;

export const selectAnswer = (assignmentId?: number) =>
  createSelector(selectAnswers, (answers) =>
    answers.find(({ assignment_id }) => assignment_id === assignmentId)
  );

export default answersSlice.reducer;
