import classNames from 'classnames';

import Loader from '../loader/Loader';
import styles from './Button.module.scss';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  isLoading?: boolean;
  label?: React.ReactNode;
  onClick?: () => void;
}

function Button({
  className,
  label,
  onClick,
  icon,
  isLoading,
  ...rest
}: ButtonProps) {
  return (
    <button
      type="button"
      className={classNames(styles.button, className)}
      onClick={onClick}
      {...rest}
    >
      {label}
      {!isLoading && icon && (
        <div
          className={classNames(styles.iconContainer, label && styles.margin)}
        >
          {icon}
        </div>
      )}
      {isLoading && (
        <div className={styles.iconContainer}>
          <Loader />
        </div>
      )}
    </button>
  );
}

Button.defaultProps = {
  icon: undefined,
  isLoading: false,
  label: undefined,
  onClick: undefined,
};

export default Button;
