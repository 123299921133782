import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { BiInfoCircle, BiLogOut } from 'react-icons/bi';
import { toast } from 'react-toastify';

import { ROUTE_LOGIN } from '../../constants/routes';
import AuthService from '../../services/AuthService';
import { errorHandler } from '../../services/HttpService';
import PlayerService, { Me } from '../../services/PlayerService';
import { setRoute } from '../../store/actions/app';
import {
  setInitialState,
  setTutorialCompletedAt,
} from '../../store/actions/player';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getAccessToken, getPlayerState, getRoute } from '../../store/reducers';
import dayjsTz from '../../utilities/dayjsTz';
import TutorialModal from '../TutorialModal';
import styles from './index.module.scss';

function GameMenu() {
  const route = useAppSelector(getRoute);
  const accessToken = useAppSelector<string>(getAccessToken);
  const playerState = useAppSelector<{
    tutorialCompletedAt?: Me['tutorial_completed_at'];
  }>(getPlayerState);
  const dispatch = useAppDispatch();

  const [tutorialModal, setTutorialModal] = useState(false);
  const { tutorialCompletedAt } = playerState;

  const toggleTutorial = () => setTutorialModal(!tutorialModal);

  const onAfterOpen = () => {
    async function postCompleteTutorial() {
      const {
        data: { tutorial_completed_at },
        status,
      } = await PlayerService.postCompleteTutorial(accessToken);

      if (status === 200)
        dispatch(setTutorialCompletedAt(tutorial_completed_at));
    }

    if (!tutorialCompletedAt)
      postCompleteTutorial().catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          /** 403 FORBIDDEN: Tutorial already completed */
          dispatch(setTutorialCompletedAt(dayjsTz().format()));
        } else {
          errorHandler(error);
        }
      });
  };

  const handleLogout = () => {
    AuthService.logout(accessToken).catch((error: AxiosError) => {
      if (error.response?.status === 204) {
        /** 204 No Content: User is logged out */
        dispatch(setRoute(ROUTE_LOGIN));
        dispatch(setInitialState());

        toast.info('Uitgelogd.', {
          pauseOnHover: false,
        });
      } else {
        errorHandler(error);
      }
    });
  };

  useEffect(() => {
    // @see: https://github.com/reactjs/react-modal/issues/808#issuecomment-1217342681
    const timeout = setTimeout(
      () => tutorialCompletedAt === null && setTutorialModal(true),
      1000
    );
    return () => clearTimeout(timeout);
  }, [tutorialCompletedAt]);

  if (route === 'ROUTE_LOGIN') return null;

  return (
    <div className={styles.container}>
      <button type="button" onClick={handleLogout}>
        Uitloggen
        <span>
          <BiLogOut />
        </span>
      </button>
      |
      <button type="button" onClick={toggleTutorial}>
        Uitleg
        <span>
          <BiInfoCircle />
        </span>
      </button>
      <TutorialModal
        isOpen={tutorialModal}
        onRequestClose={toggleTutorial}
        onAfterOpen={onAfterOpen}
      />
    </div>
  );
}

export default GameMenu;
