import { SET_ACCESS_TOKEN } from '../constants/actionTypes';

const initialState = {
  accessToken: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.accessToken };
    default:
      return state;
  }
};

export const getAccessToken = (state) => state.accessToken;

export default auth;
