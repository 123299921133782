import {
  Assignment,
  Content,
  PlayerPosition,
} from '../../services/ContentService';

const findById = <T extends { id: number }>(id: number, arr?: T[]) =>
  arr?.find((item) => item.id === id);

/**
 * @param position E.g. `head_1`
 * @param assignmentsWithForeignKeys E.g. `assignmentsWithForeignKeys(content)`
 */
const getAssignmentByPosition = (
  position: PlayerPosition,
  assignmentsWithForeignKeys: Assignment[]
) =>
  assignmentsWithForeignKeys.find(
    (assignment) => assignment.station?.reference === position
  );

/**
 * @returns Assignment with foreign keys.
 *
 * @example
 * Sample output where `assignment_type` is added, based on `assignment_type_id`:
 *
 * ```
 * {
 *   assignment_type_id: 2,
 * + assignment_type: {
 * +  id: 2,
 * +  name: 'Groot',
 * +  kilometers: 5,
 *   },
 * }
 * ```
 */
const assignmentsWithForeignKeys = ({
  assignment_types,
  assignments,
  stations,
  weeks,
}: Content) =>
  assignments.map((assignment) => {
    const { assignment_type_id, week_id, station_id } = assignment;
    const assignment_type = findById(assignment_type_id, assignment_types);
    const week = findById(week_id, weeks);
    const station = findById(station_id, stations);

    return {
      ...assignment,
      assignment_type,
      week,
      station,
    };
  });

export default assignmentsWithForeignKeys;
export { getAssignmentByPosition };
