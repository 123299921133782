import classNames from 'classnames';
import ReactModal from 'react-modal';

import CloseButton from '../closeButton/CloseButton';
import styles from './index.module.scss';

ReactModal.setAppElement('#root');

interface ModalProps extends ReactModal.Props {
  actions?: React.ReactNode;
  dark?: boolean;
  header?: React.ReactNode;
  label?: React.ReactNode;
  noPadding?: boolean;
  resetActionsStyle?: boolean;
}

function Modal({
  onRequestClose,
  children,
  className,
  actions,
  resetActionsStyle,
  dark,
  header,
  label,
  noPadding,
  ...props
}: React.PropsWithChildren<ModalProps>) {
  return (
    <ReactModal
      overlayClassName={styles.overlay}
      className={classNames(
        styles.modal,
        dark && styles.dark,
        noPadding && styles.noPadding,
        className as string
      )}
      onRequestClose={onRequestClose}
      {...props}
    >
      <div className={styles.headerContainer}>
        <div className={styles.header}>{header}</div>
        <div className={styles.buttonContainer}>
          <CloseButton label="Terug" onClick={onRequestClose} />
        </div>
      </div>
      <div className={styles.label}>{label}</div>
      <div className={classNames(styles.body, label && styles.withLabel)}>
        {children}
      </div>
      {actions && (
        <div className={resetActionsStyle ? undefined : styles.actions}>
          {actions}
        </div>
      )}
    </ReactModal>
  );
}

Modal.defaultProps = {
  actions: undefined,
  dark: false,
  header: undefined,
  label: undefined,
  open: true,
  resetActionsStyle: false,
};

export default Modal;
