import { API_URL } from '../constants/api';
import { Assignment, PlayerPosition } from './ContentService';
import HttpService from './HttpService';

export interface Me {
  completed_assignments: (Assignment['id'] | 'start')[];
  current_position: PlayerPosition;
  distance: number;
  id: number;
  name: string;
  passenger_score: number;
  tutorial_completed_at: string | null;
}

class PlayerService extends HttpService {
  static getMe(accessToken?: string) {
    return super.get<Me>(`${API_URL}/me`, accessToken);
  }

  static postCurrentPosition(position: PlayerPosition, accessToken?: string) {
    return super.post(
      `${API_URL}/me`,
      { current_position: position },
      accessToken
    );
  }

  static postCompleteTutorial(accessToken?: string) {
    return super.post<Me>(
      `${API_URL}/complete-tutorial`,
      undefined,
      accessToken
    );
  }
}

export default PlayerService;
