import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Reply } from '../../../services/RepliesService';
import type { RootState } from '../..';

interface PayloadSeen {
  id: Reply['id'];
  viewed_by_student_at: Reply['viewed_by_student_at'];
}

const initialState: Reply[] = [];

const repliesSlice = createSlice({
  name: 'replies',
  initialState,
  reducers: {
    setReplies: (_state, { payload }: PayloadAction<Reply[]>) => payload,
    updateReplySeen: (state, { payload }: PayloadAction<PayloadSeen>) => {
      const index = state.findIndex(({ id }) => id === payload.id);
      if (index !== -1)
        state[index].viewed_by_student_at = payload.viewed_by_student_at;
    },
  },
});

export const { setReplies, updateReplySeen } = repliesSlice.actions;

export const selectReplies = (state: RootState) => state.replies;
export const selectUnviewedReplies = createSelector(selectReplies, (replies) =>
  replies.filter(({ viewed_by_student_at }) => !viewed_by_student_at)
);

export const selectReply = (id?: number) =>
  createSelector(selectReplies, (replies) =>
    replies.find(({ assignment_id }) => assignment_id === id)
  );

export const selectHasReply = (id?: number) =>
  createSelector(selectReplies, (replies) =>
    replies.some(({ assignment_id }) => assignment_id === id)
  );
export const selectHasUnviewedReply = (id?: number) =>
  createSelector(selectUnviewedReplies, (replies) =>
    replies.some(({ assignment_id }) => assignment_id === id)
  );

export default repliesSlice.reducer;
