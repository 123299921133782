import { ReactComponent as CloseIcon } from './assets/close.svg';
import styles from './CloseButton.module.scss';

function CloseButton({ label, onClick }) {
  return (
    <button type="button" className={styles.closeButton} onClick={onClick}>
      {label}
      <div className={styles.iconContainer}>
        <CloseIcon />
      </div>
    </button>
  );
}

export default CloseButton;
