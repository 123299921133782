import { Week } from '../../services/ContentService';
import dayjsTz from '../dayjsTz';

const getActiveWeek = (weeks?: Week[]) => {
  const now = dayjsTz();
  const reducer = (previousValue: Week, currentValue: Week) => {
    const { active_at: previousActiveAt } = previousValue;
    const { active_at: currentActiveAt } = currentValue;
    const currentActiveAtDate = dayjsTz(currentActiveAt);

    const isCurrentLatest = currentActiveAtDate.isAfter(previousActiveAt);
    const isAfterPrevious = now.isAfter(previousActiveAt);
    const isAfterCurrent = now.isAfter(currentActiveAt);
    const isAfterBoth = isAfterPrevious && isAfterCurrent;

    if (isAfterBoth) return isCurrentLatest ? currentValue : previousValue;
    return isAfterCurrent ? currentValue : previousValue;
  };

  if (Array.isArray(weeks) && !weeks.length) return null;

  const activeWeek = weeks?.reduce(reducer);
  const activeWeekDate = dayjsTz(activeWeek?.active_at);
  // const activeWeekExpireDate = activeWeekDate.add(7, 'day');
  const isActive = now.isAfter(activeWeekDate);

  return isActive ? activeWeek : null;
};

export default getActiveWeek;
