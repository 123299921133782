import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import styles from './App.module.scss';
import GameMenu from './components/GameMenu';
import Logo from './components/logo/Logo';
import Routing from './Routing';
import { persistor, store } from './store';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className={styles.App}>
          <GameMenu />
          <div className={styles.ContentContainer}>
            <Routing />
            <Logo />
          </div>
        </div>
        <ToastContainer theme="colored" />
      </PersistGate>
    </Provider>
  );
}

export default App;
