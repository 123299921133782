import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import { toast } from 'react-toastify';

const validateStatus = (status: number) => status === 200;

/** @see https://axios-http.com/docs/handling_errors */
const errorHandler = (error: AxiosError) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    toast.error(
      `Error ${error.response.status}: ${
        // @ts-expect-error data is not defined on AxiosError
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        error.response.data.message ?? 'Unknown error'
      }`
    );
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  }
};
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class HttpService {
  static get<T>(
    endpoint: string,
    accessToken = '',
    config?: AxiosRequestConfig
  ) {
    const headers: AxiosRequestHeaders = {};

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    return axios.get<T>(endpoint, {
      headers,
      validateStatus,
      ...config,
    });
  }

  static post<T, D = unknown>(endpoint: string, data: D, accessToken = '') {
    const headers: AxiosRequestHeaders = {};

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    return axios.post<T>(endpoint, data, {
      headers,
      validateStatus,
    });
  }
}

export default HttpService;
export { errorHandler };
