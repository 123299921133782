import classNames from 'classnames';
import { useState } from 'react';

import ArrowButton from '../button/ArrowButton';
import Modal from '../Modal';
import content from './constants/content';
import styles from './index.module.scss';

function TutorialModal(props: ReactModal.Props) {
  const [index, setIndex] = useState(0);

  const currentContent = content[index] ?? content[0];
  const { label, title, text, media } = currentContent;
  const { length } = content;

  const handleNavigation = (n: number) => {
    if (index >= length - 1 && n === 1) return setIndex(0);
    if (index === 0 && n === -1) return setIndex(length - 1);

    return setIndex(index + n);
  };

  return (
    <Modal
      className={styles.modal}
      label={label}
      actions={
        <div className={styles.action}>
          {index > 0 && (
            <ArrowButton
              direction="left"
              className={styles.left}
              aria-label="Previous Tutorial"
              onClick={() => handleNavigation(-1)}
            />
          )}
          {index < length - 1 && (
            <ArrowButton
              className={styles.right}
              aria-label="Next Tutorial"
              onClick={() => handleNavigation(1)}
            />
          )}
        </div>
      }
      dark
      resetActionsStyle
      {...props}
    >
      <div className={classNames(styles.body, media && styles.grid)}>
        <div>
          <h2>{title}</h2>
          {text.map((paragraph, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={i}>{paragraph}</p>
          ))}
        </div>
        <div className={styles.media}>{media}</div>
      </div>
    </Modal>
  );
}

export default TutorialModal;
