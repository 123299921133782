import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from './components/loader/Loader';
import { ROUTE_GAME, ROUTE_LOGIN } from './constants/routes';
import { setRoute } from './store/actions/app';
import { getAccessToken, getRoute } from './store/reducers';

const Login = lazy(() => import('./pages/Login'));
const Game = lazy(() => import('./pages/Game'));

interface RouteProps {
  authProtected?: boolean;
}

function Route({
  children,
  authProtected,
}: React.PropsWithChildren<RouteProps>) {
  const accessToken = useSelector(getAccessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authProtected && !accessToken) {
      dispatch(setRoute(ROUTE_LOGIN));
    }
  }, [accessToken, authProtected, dispatch]);

  return <>{children}</>;
}

Route.defaultProps = {
  authProtected: false,
};

function Routing() {
  const route = useSelector(getRoute);
  return (
    <Suspense fallback={<Loader />}>
      {route === ROUTE_GAME ? (
        <Route authProtected>
          <Game />
        </Route>
      ) : (
        <Route>
          <Login />
        </Route>
      )}
    </Suspense>
  );
}

export default Routing;
