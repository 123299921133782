import { combineReducers } from '@reduxjs/toolkit';

import answersReducer from '../features/answers/answersSlice';
import classmatesReducer from '../features/classmates/classmatesSlice';
import contentReducer from '../features/content/contentSlice';
import repliesReducer from '../features/replies/repliesSlice';
import app, * as fromApp from './app';
import auth, * as fromAuth from './auth';
import player, * as fromPlayer from './player';

/** App */
export const getRoute = (state) => fromApp.getRoute(state.app);
export const getAccessToken = (state) => fromAuth.getAccessToken(state.auth);

/** Player */
export const getPlayerState = (state) =>
  fromPlayer.getPlayerState(state.player);
export const getCompletedAssignments = (state) =>
  fromPlayer.getCompletedAssignments(state.player);
export const getActiveAssignment = (state) =>
  fromPlayer.getActiveAssignment(state.player);
export const getPlayerPosition = (state) =>
  fromPlayer.getPlayerPosition(state.player);
export const getPlayerDistance = (state) =>
  fromPlayer.getPlayerDistance(state.player);
export const getPlayerPassengerScore = (state) =>
  fromPlayer.getPlayerPassengerScore(state.player);
export const getPlayerStateSet = (state) =>
  fromPlayer.getPlayerStateSet(state.player);
export const getPlayerName = (state) => fromPlayer.getPlayerName(state.player);

const rootReducer = combineReducers({
  answers: answersReducer,
  app,
  auth,
  classmates: classmatesReducer,
  content: contentReducer,
  player,
  replies: repliesReducer,
});

export default rootReducer;
