import { START_STATION } from '../../constants/stations';
import {
  COMPLETE_ASSIGNMENT,
  SET_ACTIVE_ASSIGNMENT,
  SET_INITIAL_STATE,
  SET_PLAYER_POSITION,
  SET_PLAYER_STATE,
  SET_TUTORIAL_COMPLETED_AT,
} from '../constants/actionTypes';

export const initialState = {
  id: null,
  completedAssignments: [],
  activeAssignment: null,
  position: START_STATION,
  distance: 0,
  passengerScore: 0,
  tutorialCompletedAt: undefined,
  name: '',
  stateSet: false,
};

const player = (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_ASSIGNMENT:
      if (
        !action.assignmentId ||
        state.completedAssignments.includes(action.assignmentId)
      )
        return state;

      return {
        ...state,
        completedAssignments: [
          ...state.completedAssignments,
          action.assignmentId,
        ],
        distance: state.distance + action.distanceToAdd,
        passengerScore: state.passengerScore + action.passengerScore,
      };
    case SET_PLAYER_POSITION:
      return { ...state, position: action.position };
    case SET_ACTIVE_ASSIGNMENT:
      return { ...state, activeAssignment: action.assignmentId };
    case SET_TUTORIAL_COMPLETED_AT:
      return { ...state, tutorialCompletedAt: action.tutorialCompletedAt };
    case SET_PLAYER_STATE: {
      const { type, ...playerState } = action;
      return { ...state, ...playerState, stateSet: true };
    }
    case SET_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
};

export const getPlayerState = (state) => state;
export const getCompletedAssignments = (state) => state.completedAssignments;
export const getPlayerPosition = (state) => state.position;
export const getActiveAssignment = (state) => state.activeAssignment;
export const getPlayerPassengerScore = (state) => state.passengerScore;
export const getPlayerDistance = (state) => state.distance;

export const getPlayerStateSet = (state) => state.stateSet;
export const getPlayerName = (state) => state.name;

export default player;
