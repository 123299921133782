import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';

import { ReactComponent as TrackIcon } from '../../assets/track_icon.svg';
import { INDIVIDUAL_DISTANCE_GOAL } from '../../constants/goals';
import { selectClassmatesTotalDistance } from '../../store/features/classmates/classmatesSlice';
import { useAppSelector } from '../../store/hooks';
import { getPlayerDistance } from '../../store/reducers';
import { ReactComponent as MultiPeopleIcon } from './assets/moreperson_icon.svg';
import Crown from './assets/Render_Kroon.png';
import { ReactComponent as SinglePersonIcon } from './assets/singleperson_icon.svg';
import styles from './DistanceIndicator.module.scss';

interface DistanceIndicatorProps {
  onClick?: () => void;
}

function DistanceIndicator({ onClick }: DistanceIndicatorProps) {
  const playerDistance = useAppSelector<number>(getPlayerDistance);
  const classmatesTotalDistance = useAppSelector(selectClassmatesTotalDistance);
  const { playerDistanceNumber } = useSpring({
    playerDistanceNumber: playerDistance,
  });
  const { totalDistanceNumber } = useSpring({
    totalDistanceNumber: playerDistance + classmatesTotalDistance,
  });

  const distanceIndicatorClassNames = cn(styles.distanceIndicator, {
    [styles.clickable]: !!onClick,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={distanceIndicatorClassNames} onClick={onClick}>
      <div className={styles.content}>
        <TrackIcon width={45} height={37} />
        <div className={styles.distanceIndicatorSelf}>
          <SinglePersonIcon width={17} height={12} />
          <h3>
            <animated.span>{playerDistanceNumber.to(Math.floor)}</animated.span>
            &nbsp;km
          </h3>
        </div>
        <div className={styles.distanceIndicatorGroup}>
          <MultiPeopleIcon width={17} height={12} />
          <h3>
            <animated.span>{totalDistanceNumber.to(Math.floor)}</animated.span>
            &nbsp;km
          </h3>
        </div>
        {playerDistance >= INDIVIDUAL_DISTANCE_GOAL && (
          <img src={Crown} className={styles.crown} alt="crown" />
        )}
      </div>
    </div>
  );
}

DistanceIndicator.defaultProps = {
  onClick: undefined,
};

export default DistanceIndicator;
