import styles from './ArrowButton.module.scss';
import { ReactComponent as ArrowIcon } from './assets/pijl_btn.svg';
import Button, { ButtonProps } from './Button';

interface ArrowButtonProps extends ButtonProps {
  direction?: 'left' | 'right';
}

function ArrowButton({
  direction = 'right',
  icon,
  ...props
}: ArrowButtonProps) {
  return (
    <Button
      icon={
        <>
          {icon}
          <ArrowIcon className={styles[direction]} />
        </>
      }
      {...props}
    />
  );
}

ArrowButton.defaultProps = {
  direction: 'right',
};

export default ArrowButton;
